// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./vars.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".admin-item{\r\n    width: 90%;\r\n    background: #fff;\r\n    box-shadow: 0px 0px 20px rgba(0,0,0,0.345);\r\n    margin: 15px 10px;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: space-evenly;\r\n    padding: 10px;\r\n    border-radius: 12px;\r\n}\r\n.admin-items{\r\n    width: 100%;\r\n}\r\n.admin-item img{\r\n    width: 70px;\r\n    height: 70px;\r\n}\r\n.handleBtn{\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: space-evenly;\r\n}\r\n.handleBtn .btn{\r\n    background: var( --primary);\r\n    color: #fff;\r\n    padding: 3px;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    margin: 5px;\r\n    cursor: pointer;\r\n    \r\n}", "",{"version":3,"sources":["webpack://./src/styles/admin.css"],"names":[],"mappings":"AAEA;IACI,UAAU;IACV,gBAAgB;IAChB,0CAA0C;IAC1C,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,WAAW;AACf;AACA;IACI,WAAW;IACX,YAAY;AAChB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;AACjC;AACA;IACI,2BAA2B;IAC3B,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;IACX,eAAe;;AAEnB","sourcesContent":["@import \"./vars.css\";\r\n\r\n.admin-item{\r\n    width: 90%;\r\n    background: #fff;\r\n    box-shadow: 0px 0px 20px rgba(0,0,0,0.345);\r\n    margin: 15px 10px;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: space-evenly;\r\n    padding: 10px;\r\n    border-radius: 12px;\r\n}\r\n.admin-items{\r\n    width: 100%;\r\n}\r\n.admin-item img{\r\n    width: 70px;\r\n    height: 70px;\r\n}\r\n.handleBtn{\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: space-evenly;\r\n}\r\n.handleBtn .btn{\r\n    background: var( --primary);\r\n    color: #fff;\r\n    padding: 3px;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    margin: 5px;\r\n    cursor: pointer;\r\n    \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
