import React ,{useState, useEffect}from 'react'
import '../styles/sidebar.css'
import {FaHome,FaBoxOpen,FaPizzaSlice,FaTimes} from 'react-icons/fa'
import { CgMenuGridR } from "react-icons/cg";
import {BsHeartHalf,BsGear} from 'react-icons/bs'
import {CgFileDocument} from 'react-icons/cg'
import { Link,useLocation } from 'react-router-dom'
import {FiLogOut} from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../actions/auth'
import { showSideBar } from '../actions'
import Message from './Message'
const SideBar = () => {
       const dispatch =useDispatch()
       const user = useSelector(state=>state.user.user)
       const show =useSelector(state=>state.sidebar.show)
       const [showM,setShowM]=useState(false)

       const location =useLocation()
       const path =location.pathname

    const handleSignOut =()=>{
          dispatch(logout())
    }

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.message-boxm')) {
                setShowM(false);
            }
        };

        if (showM) {
            document.addEventListener('click', handleClickOutside);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [showM, setShowM]);
    return (
        <div className={`sidebar  ${show&&'showSideBar'}`}  >
            <div className="close" onClick={()=>dispatch(showSideBar(false))}>
                <FaTimes/>
            </div>
            <div className="top-icons">
            <Link to="/"><div onClick={()=>dispatch(showSideBar(false))} className={`icon ${path==='/'&&'active'}`}>
                <FaHome/>{windowWidth > 1104 ? null :<p className="text-icons">Головна</p>}
            </div></Link>
            <div onClick={()=>{
                setShowM(true)
                dispatch(showSideBar(false))}} className={`icon`}>
                <CgMenuGridR />{windowWidth > 1104 ? null :<p className="text-icons">Категорії</p>}
            </div>
            <Link to="/orders"><div onClick={()=>dispatch(showSideBar(false))} className={`icon ${path==='/orders'&&'active'}`}>
                <FaBoxOpen/>{windowWidth > 1104 ? null :<p className="text-icons">Мої замовлення</p>}
            </div></Link>
            <Link to='/wishlist'> <div onClick={()=>dispatch(showSideBar(false))} className={`icon ${path==='/wishlist'&&'active'}`}>
                <BsHeartHalf/>{windowWidth > 1104 ? null :<p className="text-icons">Бажане</p>}
            </div></Link>
           <Link to="/your-address"> <div onClick={()=>dispatch(showSideBar(false))} className={`icon ${path==='/your-address'&&'active'}`}>
                <CgFileDocument/>{windowWidth > 1104 ? null :<p className="text-icons">Мої адреси</p>}
            </div></Link>
           
            <Link to='/profile'><div onClick={()=>dispatch(showSideBar(false))} className={`icon ${path==='/profile'&&'active'}`}>
                <BsGear/>{windowWidth > 1104 ? null :<p className="text-icons">Налаштування</p>} 
            </div></Link>
            {user&&(<div className="icon" onClick={handleSignOut}>
            <FiLogOut/>{windowWidth > 1104 ? null :<p className="text-icons">Вийти</p>} 
            </div>)}
            </div>

            <div className={`black-screem ${showM&&'showm'}`}>
            <div className='message-boxm'>
            <Link to="/Морепродукти"><div onClick={()=>setShowM(false)} className={`iconm`}>
                <p className="text-icons">Морепродукти</p>
            </div></Link>
            <Link to="/Риба"><div onClick={()=>setShowM(false)} className={`iconm`}>
                <p className="text-icons">Риба</p>
            </div></Link>
            <Link to="/Соуси"><div onClick={()=>setShowM(false)} className={`iconm`}>
                <p className="text-icons">Соуси</p>
            </div></Link>
            <Link to='/Імбир'> <div onClick={()=>setShowM(false)} className={`iconm`}>
                <p className="text-icons">Імбир</p>
            </div></Link>
            <Link to='/Ікра'> <div onClick={()=>setShowM(false)} className={`iconm`}>
                <p className="text-icons">Ікра</p>
            </div></Link>
           <Link to="/Васабі"> <div onClick={()=>setShowM(false)} className={`iconm`}>
                <p className="text-icons">Васабі</p>
            </div></Link>
            <Link to='/Локшина'><div onClick={()=>setShowM(false)} className={`iconm`}>
                <p className="text-icons">Локшина</p>
            </div></Link>
            <Link to='/Сири, молоко'><div onClick={()=>setShowM(false)} className={`iconm`}>
                <p className="text-icons">Сири, молоко</p>
            </div></Link>
            <Link to='/Водорості, гриби'><div onClick={()=>setShowM(false)} className={`iconm`}>
                <p className="text-icons">Водорості, гриби</p>
            </div></Link>
            <Link to='/Борошно, панірування'><div onClick={()=>setShowM(false)} className={`iconm`}>
                <p className="text-icons">Борошно, панірування</p>
            </div></Link>
            <Link to='/Палички, килимки'><div onClick={()=>setShowM(false)} className={`iconm`}>
                <p className="text-icons">Палички, килимки</p>
            </div></Link>
            <Link to='/Супутні товари'><div onClick={()=>setShowM(false)} className={`iconm`}>
                <p className="text-icons">Супутні товари</p>
            </div></Link>
            
            </div>
        </div>

        </div>
    )
}

export default SideBar
