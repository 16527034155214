export const FETCH_PIZZAS = 'FETCH_PIZZAS';
export const FETCH_PIZZAS_SUCCESS = "FETCH_PIZZAS_SUCCESS";
export const ERROR = "FETCH_ERROR";
export const ADD_TO_CART = "ADD_TO_CART";
export const UPDATE_QTY = "UPDATE_QTY";
export const DELETE_ITEM = "DELETE_ITEM";
export const SIGNIN_REQUEST = "SIGNIN_REQUEST";
export const SIGNIN = "SIGNIN";
export const SIGNUP_REQUEST = "SIGNIN_REQUEST";
export const SIGNUP = "SIGNUP";
export const LOGOUT = "LOGOUT";
export const AUTH_ERROR = "AUTH_ERROR";
export const ADD_ADDRESS = "ADD_ADDRESS";
export const GET_ADDRESS = "GET_ADDRESS";
export const UPDATE_ADDRESS = "UPDATE_ADDRESS";
export const DELETE_ADDRESS = "DELETE_ADDRESS";
export const SELECET_ADDRESS = "SELECET_ADDRESS";
export const ADDRESS_ERROR = "ADDRESS_ERROR";
export const PAYMENT_METHOD = "PAYMENT_METHOD";
export const CREATE_ORDER = "CREATE_ORDER";
export const CREATE_ORDER_REQUEST = "CREATE_ORDER_REQUEST";
export const CREATE_ORDER_ERROR = "CREATE_ORDER_ERROR";
export const DELETE_CART = "DELETE_CART";
export const ORDER_DETAILS_REQURST = "ORDER_DETAILS_REQURST";
export const ORDER_DETAIlS = "ORDER_DETAIlS";
export const ORDER_DETAILS_FAIL = "ORDER_DETAILS_FAIL";
export const UPDATE_PROFILE_REQ = "UPDATE_PROFILE_REQ";
export const UPADTE_PROFILE = "UPADTE_PROFILE";
export const UPDATE_PROFILE_ERROR = "UPDATE_PROFILE_ERROR";
export const SHEARCH_REQ = "SHEARCH_REQ";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const SERACH_ERROR = "SEARCH_SUCCESS";
export const ADD_WISHLIST_REQ = "ADD_WISHLIST_REQ";
export const ADD_WISHLIST_SUCCESS = "ADD_WISHLIST_SUCCESS";
export const ADD_WISHLIST_ERROR = "ADD_WISHLIST_ERROR";
export const GET_WISHLIST = "GET_WISHLIST";
export const DELETE_WISHLIST_ITEM = "DELETE_WISHLIST_ITEM";
export const SHOW_SIDEBAR = "SHOW_SIDEBAR";