import React, { useState, useEffect } from 'react'
import Message from '../components/Message'
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import pizza from '../apis/pizza'
import { doc, setDoc } from "firebase/firestore";
import { db, firestore } from '../firebase'
import { useLocation } from "react-router-dom";
import '../styles/bigCart.css'


const dropzoneStyles = {
  border: '2px dashed #ccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
};

const AddProduct = () => {
  const location = useLocation();
  const { product } = location.state || {};


  const [name, setName] = useState("")
  const [des, setDes] = useState("")
  const [price, setPrice] = useState()
  const [show, setShow] = useState(false)
  const [category, setCategory] = useState("")
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState(null);
  const [image, setImage] = useState('');
  const [dollar, setDollar] = useState('');
  console.log(dollar)

  async function fetchExchangeRate() {
    try {
        const response = await fetch('https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?valcode=USD&json');
        
        if (!response.ok) {
            throw new Error('Failed to fetch exchange rate');
        }
        
        const data = await response.json();
        return data[0].rate;
    } catch (error) {
        console.error('Error fetching exchange rate:', error);
        throw error;
    }
}

fetchExchangeRate()
    .then(rate => {
      setDollar(rate)
    })

  useEffect(() => {
    if (product && Object.keys(product).length > 0) {
      setName(product.name || "");         // Если продукт есть, берем его свойства, иначе оставляем пустым
      setDes(product.description || "");
      setPrice(parseFloat((product.price / dollar).toFixed(1)) || "");
      setCategory(product.category || "");
      setImage(product.image || '');
    }
    console.log(product)
  }, [product,dollar]);

  


  const onDrop = async (acceptedFiles) => {
    const selectedFile = acceptedFiles[0];
    setFile(selectedFile);

    // Отправляем файл на сервер только после обновления состояния
    const formData = new FormData();
    formData.append('image', selectedFile); // используем selectedFile напрямую

    try {
      const response = await axios.post('https://sushi-foods.store/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      
      const urlImg = `https://sushi-foods.store/api/${response.data.url}`;
      setImage(urlImg); // Сохраняем URL изображения
    } catch (error) {
      console.error('Error uploading image:', error);
    }
};

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*' });

  const myStyle = {
    width: '327px',
    height: '200px',
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const newProd = {
      name,
      description: des,
      price: price,
      image: image,
      category
    }

    try {
      if(product && Object.keys(product).length > 0){
        await pizza.put(`/api/products/update-product/${product._id}`, newProd);
      }else{
        await pizza.post("/api/products/add-product", newProd);
      }

      // Прочие действия после успешного добавления продукта
   

      setLoading(false);
      setShow(true);
    } catch (error) {
      console.error('Error uploading image:', error);
      setLoading(false);
      // Обработка других ошибок
    }
  };


  return (
    <>

      <div className='mainarea admin '>


        <div className='auth-add'>
          <div className="form">
            <form>
              <input type="text" value={name} onChange={(e) => setName(e.target.value)} name='name' placeholder='Product Name' />
              <input type="text" value={price} onChange={(e) => setPrice(e.target.value)} name="price" id="" placeholder='Price' />
              <textarea style={myStyle} value={des} type="text" onChange={(e) => setDes(e.target.value)} name='description' placeholder='description'></textarea>
              <select value={category} onChange={(e) => setCategory(e.target.value)} >
                <option value="">Category</option>
                <option value="Морепродукти">Морепродукти</option>
                <option value="Риба">Риба</option>
                <option value="Соуси">Соуси</option>
                <option value="Рис">Рис</option>
                <option value="Імбир">Імбир</option>
                <option value="Ікра">Ікра</option>
                <option value="Васабі">Васабі</option>
                <option value="Локшина">Локшина</option>
                <option value="Сири, молоко">Сири, молоко</option>
                <option value="Водорості, гриби">Водорості, гриби</option>
                <option value="Борошно, панірування">Борошно, панірування</option>
                <option value="Палички, килимки">Палички, килимки</option>
                <option value="Супутні товари">Супутні товари</option>
              </select>
              <div>
                <div {...getRootProps()} style={dropzoneStyles}>
                  <input {...getInputProps()} />
                  <p>Перетягніть зображення сюди або натисніть, щоб вибрати його</p>
                </div>
                <div className="big-product-image">
                <img src={image} alt="" />
              </div>
                {file && (
                  <div>
                    <p>Selected file: {file.name}</p>
                  </div>
                )}
              </div>
              <button onClick={handleSubmit}>Добавить товар</button>
            </form>

          </div>
        </div>

      </div>

      <Message
        showModal={show}
        msg={"Product Added Successfuly"}
        img={"https://cdn.dribbble.com/users/335541/screenshots/7102045/media/5b7237fe7bbfa31531d6e765576f1bc4.jpg?compress=1&resize=400x300"}
        type="error"
        closeModal={setShow}
      />
    </>
  )
}

export default AddProduct