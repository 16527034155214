import React, { useEffect, useState } from 'react';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';
import { IoMdAdd } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../../actions/cart';
import { addToWishlist, deleteItemFromWishlist, getWishlist } from '../../actions/wishlist';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Spinner from '../Spinner';
import BigCart from '../BigCart.js';
import pizza from '../../apis/pizza';
import { fetchPizzas } from '../../actions';

const ProductCard = ({ product, category }) => {
    const allPizzas = useSelector(state => state.allPizza);
    const { loading, error, data } = allPizzas;

    const user = useSelector(state => state.user.user);
    const wishlist = useSelector(state => state.wishlist);
    const [currenItemClicked, setCurrenItem] = useState();
    const [index, setIndex] = useState([]);
    const [products, setProducts] = useState([]);
    const [show, setShow] = useState(false);
    const [saveCategory, setSaveCategory] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (product) {
            setProducts(product);
        } else if (data) {
            setProducts(data);
        }
    }, [product, data]);

    useEffect(() => {
        setSaveCategory(category);
    }, [category]);

    const cartHandler = (item) => {
        dispatch(addToCart(item));
    };

    const deleteCart = async (id) => {
        await pizza.delete(`/api/products/del-product/${id}`, {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        });
        dispatch(fetchPizzas(saveCategory));
    };

    const pathname = useLocation().pathname;

    const openBigCart = (i) => {
        setIndex(i);
        setShow(true);
    };

    const handleWishlist = (item) => {
        setCurrenItem(item._id);
        dispatch(addToWishlist({
            name: item?.name,
            image: item?.image,
            rating: item?.rating,
            price: item?.price,
            _id: item?._id,
            description: item?.description
        }));
    };

    const handleRemoveWishlist = (id) => {
        setCurrenItem(id);
        dispatch(deleteItemFromWishlist(id)).then(() => {
            dispatch(getWishlist()); // Повторно загружаем избранное
        });
    };
    useEffect(() => {
        dispatch(getWishlist());
    }, []);

    const getStars = (rating) => {
        if (rating > 4) {
            return (<><FaStar /><FaStar /><FaStar /><FaStar /><FaStar /></>);
        }
        if (rating === 4) {
            return (<><FaStar /><FaStar /><FaStar /><FaStar /><FaRegStar /></>);
        }
        if (rating > 3) {
            return (<><FaStar /><FaStar /><FaStar /><FaStarHalfAlt /><FaRegStar /></>);
        }
        if (rating === 3) {
            return (<><FaStar /><FaStar /><FaStar /><FaRegStar /><FaRegStar /></>);
        }
    };

    const getState = (item) => {
        if (wishlist.loading && item._id === currenItemClicked) {
            return <Spinner color={'#eb3d34'} />;
        }
        const isInWishlist = wishlist?.wishlistItems?.some(x => x.product === item._id);
        return isInWishlist ? <AiFillHeart /> : <AiOutlineHeart />;
    };

    return (
        <>
            {products?.map((item, i) => (
                <div onClick={() => openBigCart(i)} key={i} className='product-card'>
                    {user?.isAdmin && 
                        <button className='button-delete' onClick={(e) => { e.stopPropagation(); deleteCart(item._id) }}>
                            Видалити
                        </button>
                    }
                    {pathname !== "/wishlist" && 
                        <div 
                            className="love" 
                            onClick={(e) => { 
                                e.stopPropagation();
                                const wishlistItem = wishlist?.wishlistItems?.find(x => x.product === item._id);
                                if (wishlistItem) {
                                    handleRemoveWishlist(wishlistItem._id);
                                } else {
                                    handleWishlist(item);
                                }
                            }}
                        >
                            {getState(item)}
                        </div>
                    }
                    <div className="img">
                        <img src={item?.image} alt="" />
                    </div>
                    <div className="des">
                        <h3>{item?.name}</h3>
                        <div className='starts'>{getStars(item?.rating)}</div>
                        <p className='price'>{item?.price}<span> грн</span></p>
                    </div>
                    {user?.isAdmin ? 
                        <Link to="/add-product" state={{ product: item }}>
                            <button>Редагувати</button>
                        </Link> :
                        <div className="add-button" onClick={(e) => { e.stopPropagation(); cartHandler(item); }}>
                            <IoMdAdd />
                        </div>
                    }
                </div>
            ))}
            <BigCart
                showModal={show}
                cartHandler={cartHandler}
                product={data?.[index]} 
                closeModal={setShow}
            />
        </>
    );
};

export default ProductCard;