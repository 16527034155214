import React, { useEffect, useState } from 'react';
import '../styles/auth.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../assests/logo.png';
import Spinner from '../components/Spinner';
import { siginUser } from '../actions/auth';

const Signin = () => {
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const location = useLocation();
    const navigate = useNavigate();
    const userInfo = user?.user;
    const redirect = location.search ? `/${location.search.split('=')[1]}` : '/';

    // form validation
    let schema = yup.object().shape({
        phone: yup.string().matches(/^\+380\d{9}$/, 'Please enter a valid phone number').required('Please enter your phone number'),
        password: yup.string().required('Please enter your password').min(6, 'Password must be at least 6 characters'),
    });

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const submitHandler = (data) => {
        const { phone, password } = data;
        dispatch(siginUser( phone, password));  // Using null for name since we removed it from form
        setLoading(true);
    };

    useEffect(() => {
        if (userInfo) {
            navigate(redirect);
        }
    }, [userInfo]);

    return (
        <div className='auth'>
            <div className="form">
                <div className="logo"></div>
                {user?.error && (<div className="err">{user?.error}</div>)}
                <form onSubmit={handleSubmit(submitHandler)}>
                    <input className="inputAuth" type="tel" name='phone' placeholder='Телефон' {...register('phone')} />
                    {errors?.phone?.message && <p className="err">{errors?.phone?.message}</p>}
                    <input className="inputAuth" type="password" name="password" placeholder='Пароль' {...register('password')} />
                    {errors?.password?.message && <p className="err">{errors?.password?.message}</p>}
                    <div className="text">
                        <Link to="/updatepassword">Загубили пароль?</Link>
                    </div>
                    <button type="submit">{isLoading ? <Spinner /> : 'Увійти'}</button>
                </form>
                <div className="forget">
                    <p>Новий відвідувач? </p>
                </div>
                <div className="forget">
                    <Link to="/signup">Реєстрація</Link>
                </div>
            </div>
        </div>
    );
}

export default Signin;