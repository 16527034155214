import React, { useEffect, useState } from 'react';
import '../styles/auth.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../assests/logo.png';
import Spinner from '../components/Spinner';
import { signupUser } from '../actions/auth';

const Signin = () => {
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const location = useLocation();
    const navigate = useNavigate();
    const userInfo = user?.user;
    const redirect = location.search ? `/${location.search.split('=')[1]}` : '/';

    // form validation
    let schema = yup.object().shape({
        name: yup.string().required('Please enter your name'),
        phone: yup.string()
            .matches(/^\+380\d{9}$/, 'Phone number must start with +380 and have 9 more digits')
            .required('Please enter your phone number'),
        password: yup.string()
            .required('Please enter your password')
            .min(6, 'Password must be at least 6 characters long'),
        confirmPassword: yup.string()
            .oneOf([yup.ref('password'), null], 'Passwords must match')
            .required('Please confirm your password')
    });

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const submitHandler = (data) => {
        const { name, phone, password } = data;
        dispatch(signupUser(name, phone, password));
        setLoading(true);
    };

    useEffect(() => {
        if (userInfo) {
            navigate(redirect);
        }
    }, [userInfo]);

    return (
        <div className='auth'>
            <div className="form">
                <div className="logo"></div>
                {user?.error && (<div className="err">{user?.error}</div>)}
                <form onSubmit={handleSubmit(submitHandler)}>
                    <input className="inputAuth" type="text" name='name' placeholder="Ім'я" {...register('name')} />
                    {errors?.name?.message && <p className="err">{errors?.name?.message}</p>}
                    <input className="inputAuth" type="tel" name='phone' placeholder='Телефон' {...register('phone')} />
                    {errors?.phone?.message && <p className="err">{errors?.phone?.message}</p>}
                    <input className="inputAuth" type="password" name="password" placeholder='Пароль' {...register('password')} />
                    {errors?.password?.message && <p className="err">{errors?.password?.message}</p>}
                    <input className="inputAuth" type="password" name="confirmPassword" placeholder='Підтвердіть пароль' {...register('confirmPassword')} />
                    {errors?.confirmPassword?.message && <p className="err">{errors?.confirmPassword?.message}</p>}
                    <button type="submit">{user?.loading ? <Spinner /> : 'Зареєструватися'}</button>
                </form>
                <div className="forget">
                </div>
                <div className="forget">
                    <Link to="/signin">Увійти</Link>
                </div>
            </div>
        </div>
    );
}

export default Signin;